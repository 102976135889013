import React from "react"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"

import Layout from "src/layout"
import { useLang } from "src/layout/root"
import { Top2, Section } from "src/components/styled"
import Inspirations from "src/components/Inspirations"

interface DataType {
  projects: {
    nodes: Project[]
  }
}

const InspirationsPage: GatsbyPage<DataType> = ({ data }) => {
  const { getNodes } = useLang()
  const list = getNodes<Project>(data.projects)

  return (
    <Layout title="inspirations">
      <Top2>
        <FormattedMessage id="inspirations.title" tagName="h1" />
        <FormattedMessage id="inspirations.text" tagName="p" />
      </Top2>
      <Section>
        <Inspirations list={list} />
      </Section>
    </Layout>
  )
}

export default InspirationsPage

export const query = graphql`
  query {
    projects: allContentfulProjet(sort: { fields: position }) {
      nodes {
        node_locale
        slug
        photo {
          fluid(maxWidth: 800, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
